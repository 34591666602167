import React, { MouseEvent } from 'react';

import { Showtime } from 'types/graphql-api.generated';

import ShowtimeExperiences from 'common/components/Showtimes/ShowtimeExperiences';
import {
  TheaterReservationTracking,
  TrackingEventNames
} from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import trans from 'common/tools/translations/trans';

export type TicketingUrl = string | null;

type ShowtimesHourProps = {
  className?: string;
  experiences?: Showtime['tags'];
  hour: string;
  openModal?: (
    fallback: (() => void) | null,
    tracking: TheaterReservationTracking
  ) => void;
  ticketingUrl?: TicketingUrl;
  tracking: TheaterReservationTracking;
};

const onReservationLinkClick = (trackingData: TheaterReservationTracking) => {
  eventEmitter.emit(
    TrackingEventNames.THEATER_RESERVATION_LINK_CLICK_EVENT,
    trackingData
  );
};

const ShowtimesHour = ({
  className = '',
  experiences = [],
  hour,
  openModal,
  ticketingUrl = null,
  tracking
}: ShowtimesHourProps) => {
  const hourItemClass = `showtimes-hour-item ${className}`;

  // Due to some blockers such as Ghostery, the modal
  // window is not visible, we fall back on opening the
  // booking page.
  const modalFallback = () => {
    if (ticketingUrl) {
      window.open(ticketingUrl, '_blank');
      onReservationLinkClick(tracking);
    }
  };

  const handleClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    if (openModal) {
      e.preventDefault();
      openModal(modalFallback, tracking);
    } else {
      onReservationLinkClick(tracking);
    }
  };

  let hourItem = (
    <span className={hourItemClass}>
      <span
        className="showtimes-hour-item-value"
        onClick={() => openModal?.(null, tracking)}
      >
        {hour}
      </span>
    </span>
  );

  if (ticketingUrl) {
    hourItem = (
      <a
        className={hourItemClass}
        title={trans('showtimes.booking.txt')}
        href={ticketingUrl}
        // Some ticketing providers have to trace the referrer of showtimes link
        // we are so forced to set the target blank without noreferrer rel (only here)
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <span className="showtimes-hour-item-value">{hour}</span>
        <span className="showtimes-hour-item-booking">
          {trans('showtimes.booking.txt')}
        </span>
      </a>
    );
  }

  return (
    <div className="showtimes-hour-block">
      {hourItem}
      <ShowtimeExperiences
        className="showtimes-hour-block-amenities"
        experiences={experiences}
        colorfulIcon={false}
      />
    </div>
  );
};

export default ShowtimesHour;
